


















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { OfflineReportExplanationService, FileService } from "src/services";
import { Toast } from "vant";
import { Service } from "src/decorator";
import pageBack from "src/components/page-back";
import patterns from "src/utils/patterns";
import dayjs from "dayjs";

@Component({
    components:
    {
        pageBack
    }
})
export default class PowerReportForm extends Vue
{

    /**
     * 上传文件 - 图片
     * @protected
     * @reyurns Array<any>
     */
    protected uploaderImg: Array<any> = [];

    /**
     * 上传文件
     * @protected
     * @reyurns Array<any>
     */
    protected uploaderPdf: Array<any> = [];

    // 当前日期后3天
    private dateThree: any = new Date(dayjs().add(1, 'day').valueOf());

    /**
     * 模型
     * @private
     * @returns string
     */
    private modelData: any =
    {
        name: "",
        phone: "",
        reserveDate: "",
        remark: "",
        reportFiles: ""
    };

    /**
     * 点击获取验证码等60秒完成后才可以重新点击发送
     * @private
     * @returns boolean
     */
    private isDisabled: boolean = false;

    /**
     * 获取验证码
     * @private
     * @returns string
     */
    private sendCode: string = "获取验证码";

    /**
     * 获取验证码60秒
     * @private
     * @returns string
     */
    private time: number = 60;

    /**
     * 验证是否正确手机号
     * @private
     * @returns void
     */
    private validatorPhone(val: any): any
    {
        return patterns.mobile.test(val);
    }

    /**
     * 跳转隐私
     * @private
     * @returns void
     */
    private goPrivacyFile(): void
    {
        this.$router.push({name: "privacyFile"});
    }

    /**
     * 跳转我的报告列表页面
     * @private
     * @returns void
     */
    private async onSave(): Promise<void>
    {
        try
        {
            if(this.verifyInfo())
            {
                await OfflineReportExplanationService.instance.addOfflineReportExplanation(this.modelData);
                Toast("提交成功！");
                setTimeout(() => {
                    this.onPowerReport();
                }, 500)
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 跳转报告解读列表
     * @private
     * @returns void
     */
    private onPowerReport(): any
    {
        this.$router.push({name: "powerReportOrderList"});
    }

    /**
     * 验证身份信息查询表单
     * @private
     * @returns void
     */
    private verifyInfo(): boolean
    {
        if(!this.modelData.name)
        {
            Toast({
                duration: 1000,
                message: "请输入预约人！"
            });

            return false;
        }

        if(!this.modelData.phone || this.modelData.phone.length !== 11)
        {
            Toast({
                duration: 1000,
                message: "请输入正确手机号码！"
            });

            return false;
        }

        if(this.uploaderImg.length == 0 && this.uploaderPdf.length == 0)
        {
            Toast({
                duration: 1000,
                message: "请先上传报告！"
            });

            return false;
        }

        return true;
    }

    /**
     * 上传文件 - 图片
     * @private
     * @returns Promise<void>
     */
    private async onUploadFileImg(): Promise<void>
    {
        this.verifyInfo();
        if (this.uploaderImg.length > 0)
        {
            try
            {
                let formData = new FormData();
                this.uploaderImg.forEach(item=>
                {
                    formData.append("files", item.file);
                })
                formData.append("useType", "1");
                let {content: result} = await FileService.instance.uploadFile(formData);
                if (result.data)
                {
                    let ids = "";
                    result.data.forEach(item => {
                        ids += item.id + ','
                    });
                    this.modelData.reportImages = ids.substring(0, ids.length - 1);
                    this.onUploadFilePdf();
                }
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
        }
        else
        {
            this.onUploadFilePdf();
        }
    }

    /**
     * 上传文件 - Pdf
     * @private
     * @returns Promise<void>
     */
    private async onUploadFilePdf(): Promise<void>
    {
        if (this.uploaderPdf.length > 0)
        {
            try
            {
                let formData = new FormData();
                this.uploaderPdf.forEach(item=>
                {
                    formData.append("files", item.file);
                })
                formData.append("useType", "4");
                let {content: result} = await FileService.instance.uploadFile(formData);
                if (result.data)
                {
                    let ids = "";
                    result.data.forEach(item => {
                        ids += item.id + ','
                    });
                    this.modelData.reportFiles = ids.substring(0, ids.length - 1);
                    this.onSave();
                }
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
        }
        else
        {
            this.onSave();
        }
    }

}
