


















































import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { Service } from "src/decorator";
import { OfflineReportExplanationService } from "src/services";
import dayjs from "dayjs";

@Component
export default class OrderList extends Vue
{

    /**
     * tab选中
     * @private
     * @returns number | string
     */
    private active: number | string = "";

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 获取订单列表 
     */
    protected async loadData(parameter: any): Promise<any> {
        try {
            let { content: res } = await OfflineReportExplanationService.instance.offlineReportExplanationList(
                Object.assign({status: this.active == "" ? [] : [parseFloat(this.active as string)]}, parameter)
            );
            return res.data;
        } catch (err) {
            Toast(err);
        }
    }

    /**
     * tab切换
     * @private
     * @returns void
     */
    private onTabChange(item: number): void
    {
        (this.$refs.myUPageList as any).onRefresh();
    }

     /**
     * 取消
     * @private
     * @returns void
     */
    private onCancel(id: number): void
    {
        Dialog.confirm({
            title: '提示',
            message: '确定要取消吗？',
        })
        .then(async () => {
            try
            {
                await OfflineReportExplanationService.instance.cancelOfflineReportExplanation(id);
                Toast("取消成功！");
                (this.$refs.myUPageList as any).onRefresh();
            }
            catch (err)
            {
                Toast(err)
            }
        })
        .catch(() => {
            // on cancel
        });
    }


}
